import React from "react"
import { graphql } from "gatsby"

import MainLayout from "../components/main-layout"
import SEO from "../components/seo"
import PageHeader from "../components/page-header"

class PrivacyPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <MainLayout location={this.props.location} title={siteTitle}>
        <SEO title="Privacy Policy" />
        <div className="container mx-auto">
          <div className="h-auto py-12 space-y-4">
            <PageHeader>Privacy Policy for Joyful Noise Living</PageHeader>
            <p>
              This Privacy Policy governs the manner in which Joyful Noise
              Learning uses, maintains and discloses information collected from
              users (each, a “User”) of the
              <a href="https://joyfulnoiselearning.com">
                https://joyfulnoiselearning.com
              </a>
              website (“Site”). This privacy policy applies to the Site and all
              products and services offered by JoyfulNoiseLearning.com.
            </p>
            <h2 className="font-bold" id="personal-identification-information">
              PERSONAL IDENTIFICATION INFORMATION
            </h2>
            <p>
              We may collect personal identification information from Users in a
              variety of ways, including, but not limited to, when Users visit
              our site, register on the site, place an order, subscribe to the
              newsletter, respond to a survey, and in connection with other
              activities, services, features or resources we make available on
              our Site. Users may be asked for, as appropriate, name, email
              address, credit card information. Users may, however, visit our
              Site anonymously. We will collect personal identification
              information from Users only if they voluntarily submit such
              information to us. Users can always refuse to supply personal
              identification information, except that it may prevent them from
              engaging in certain Site related activities.
            </p>
            <h2
              className="font-bold"
              id="non-personal-identification-information"
            >
              NON-PERSONAL IDENTIFICATION INFORMATION
            </h2>
            <p>
              We may collect non-personal identification information about Users
              whenever they interact with our Site. Non-personal identification
              information may include the browser name, the type of computer and
              technical information about Users means of connection to our Site,
              such as the operating system and the Internet service providers
              utilized and other similar information.
            </p>
            <h2 className="font-bold" id="web-browser-cookies">
              WEB BROWSER COOKIES
            </h2>
            <p>
              Our Site may use “cookies” to enhance User experience. User’s web
              browser places cookies on their hard drive for record-keeping
              purposes and sometimes to track information about them. User may
              choose to set their web browser to refuse cookies, or to alert you
              when cookies are being sent. If they do so, note that some parts
              of the Site may not function properly.
            </p>
            <h2 className="font-bold" id="how-we-use-collected-information">
              HOW WE USE COLLECTED INFORMATION
            </h2>
            <p>
              JoyfulNoiseLearning.com may collect and use Users personal
              information for the following purposes: To improve customer
              service Information you provide helps us respond to your customer
              service requests and support needs more efficiently. To
              personalize user experience We may use information in the
              aggregate to understand how our Users as a group use the services
              and resources provided on our Site. To improve our Site
            </p>
            <p>
              We may use feedback you provide to improve our products and
              services.
            </p>
            <p>To process payments</p>
            <p>
              We may use the information Users provide about themselves when
              placing an order only to provide service to that order. We do not
              share this information with outside parties except to the extent
              necessary to provide the service.
            </p>
            <p>To run a promotion, contest, survey or other Site feature</p>
            <p>
              To send Users information they agreed to receive about topics we
              think will be of interest to them.
            </p>
            <p>To send periodic emails</p>
            <p>
              We may use the email address to send User information and updates
              pertaining to their order. It may also be used to respond to their
              inquiries, questions, and/or other requests. If User decides to
              opt-in to our mailing list, they will receive emails that may
              include company news, updates, related product or service
              information, etc. If at any time the User would like to
              unsubscribe from receiving future emails, we include detailed
              unsubscribe instructions at the bottom of each email.
            </p>
            <h2 className="font-bold" id="how-we-protect-your-information">
              HOW WE PROTECT YOUR INFORMATION
            </h2>
            <p>
              We adopt appropriate data collection, storage and processing
              practices and security measures to protect against unauthorized
              access, alteration, disclosure or destruction of your personal
              information, username, password, transaction information and data
              stored on our Site.
            </p>
            <h2 className="font-bold" id="sharing-your-personal-information">
              SHARING YOUR PERSONAL INFORMATION
            </h2>
            <p>
              We do not sell, trade, or rent Users personal identification
              information to others. We may share generic aggregated demographic
              information not linked to any personal identification information
              regarding visitors and users with our business partners, trusted
              affiliates and advertisers for the purposes outlined above. We may
              use third party service providers to help us operate our business
              and the Site or administer activities on our behalf, such as
              sending out newsletters or surveys. We may share your information
              with these third parties for those limited purposes provided that
              you have given us your permission.
            </p>
            <h2 className="font-bold" id="third-party-websites">
              THIRD PARTY WEBSITES
            </h2>
            <p>
              Users may find advertising or other content on our Site that link
              to the sites and services of our partners, suppliers, advertisers,
              sponsors, licensors and other third parties. We do not control the
              content or links that appear on these sites and are not
              responsible for the practices employed by websites linked to or
              from our Site. In addition, these sites or services, including
              their content and links, may be constantly changing. These sites
              and services may have their own privacy policies and customer
              service policies. Browsing and interaction on any other website,
              including websites which have a link to our Site, is subject to
              that website’s own terms and policies.
            </p>
            <h2 className="font-bold" id="changes-to-this-privacy-policy">
              CHANGES TO THIS PRIVACY POLICY
            </h2>
            <p>
              JoyfulNoiseLearning.com has the discretion to update this privacy
              policy at any time. When we do, we will revise the updated date at
              the bottom of this page. We encourage Users to frequently check
              this page for any changes to stay informed about how we are
              helping to protect the personal information we collect. You
              acknowledge and agree that it is your responsibility to review
              this privacy policy periodically and become aware of
              modifications.
            </p>
            <h2 className="font-bold" id="your-acceptance-of-these-terms">
              YOUR ACCEPTANCE OF THESE TERMS
            </h2>
            <p>
              By using this Site, you signify your acceptance of this policy. If
              you do not agree to this policy, please do not use our Site. Your
              continued use of the Site following the posting of changes to this
              policy will be deemed your acceptance of those changes.
            </p>
            <h2 className="font-bold" id="contacting-us">
              CONTACTING US
            </h2>
            <p>
              If you have any questions about this Privacy Policy, the practices
              of this site, or your dealings with this site, please contact us
              at:
            </p>
            <p>
              <strong>Ashley Weaver</strong>
              <a href="https://joyfulnoiselearning.com">
                https://joyfulnoiselearning.com
              </a>
            </p>
            <p>Ashley @ joyfulnoiselearning .com</p>
            <p>This document was last updated on April 25, 2020</p>
          </div>
        </div>
      </MainLayout>
    )
  }
}

export default PrivacyPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
